import React from 'react';


const ProductDetailsCard = ({ imageSrc, heading, description }) => {
  return (
    <div className="card mb-3 product-details-card shadow">
      <div className="row no-gutters">
        <div className="col-md-4">
          <img src={imageSrc} className="card-img" alt="Card" />
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{heading}</h5>
            <p className="card-text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsCard;
