import React, {useState, useRef} from "react";
// import Product from "../Ui/Product";
import WEB_CONFIG from "../../config";
// import ModalComponent from "../Ui/Modal";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SupplierHeaderImage from '../Images/sections_image/Suppliers.avif';

const Suppliers = ()=>{
  const [inputFields, setInputFields] = useState({
    from_name: "",
    user_email: "",
    mobile: '',
    country:'',
    comment:''
  });

  const [isEmailSend, setEmailSend] = useState(false);

  const form = useRef();

  const validateValues = (inputValues) => {
    if (!inputValues.from_name || !inputValues.user_email || 
      !inputValues.mobile || !inputValues.country || !inputValues.comment) {
      return false;
    }else{
      return true;
    }
  
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const isFormValid = validateValues(inputFields)
    if(!isFormValid){
      return;
    }else{
      console.log();
      setEmailSend(true);
      emailjs.sendForm(WEB_CONFIG.emailConfig.serviceID, WEB_CONFIG.emailConfig.templateIDSupplier, form.current, WEB_CONFIG.emailConfig.publicKey)
        .then((result) => {
          if(result.text){
            setEmailSend(false);
            toast("Your query has been raised!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type:'success',
              });
          }
        }, (error) => {
          setEmailSend(false);
          toast("Something went wrong! Please try after some time",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type:'error',
            });
        });
    }
    

  };


    return (
    <div className="suppliers-component">

      <img
        className="img-fluid"
        src={SupplierHeaderImage}
        alt="Products"
        style={{ width: '100%', height: '55vh', objectFit: 'cover' }}
      />
      
      <section id="service" className="services">
      <div className="container">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="section-header">
                <span>Suppliers</span>
                <h2>Suppliers</h2>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
                {/* <h3>About Us</h3> */}
                <p className="text-justify">
                Elevate your global market presence with <b>AMRUTSYA</b>. We serve as your dedicated representative, strategically expanding your product reach across diverse geographies where demand exists. 
                Fill in the form below to kickstart your international business growth. The more details you share, the better we can represent you effectively. 
                At <b>AMRUTSYA</b>, we value our suppliers as "Partners in Progress," committed to scaling up your business globally. 
                <br/><br/>

                Join us in propelling your brand to new heights in the international market.
                </p>
              
                
              </div>
        </div>
        <div className="row mt-5 form-container mb-4">
                <Form ref={form}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                        controlId="floatingInputName"
                        label="Name"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Name" 
                        name="from_name"  value={inputFields.from_name}
                        onChange={handleChange} required />
                      </FloatingLabel>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                        controlId="floatingInputEmail"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control type="email" placeholder="name@example.com" 
                        name="user_email"  value={inputFields.user_email}
                        onChange={handleChange} required />
                  </FloatingLabel>
                  </div>

                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                        controlId="floatingInputMobile"
                        label="Mobile number"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Mobile number" 
                        name="mobile"  value={inputFields.mobile}
                        onChange={handleChange} required/>
                      </FloatingLabel>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                        controlId="floatingInputProduct"
                        label="Country"
                        className="mb-3"
                      >
                        <Form.Control type="text" placeholder="Country" 
                        name="country"  value={inputFields.country}
                        onChange={handleChange} required/>
                  </FloatingLabel>
                  </div>

                </div>

                <div className="row" >
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <FloatingLabel
                        controlId="floatingInputComments"
                        label="Additional Comments"
                        className="mb-3"
                      >
                        <Form.Control as="textarea" rows={3} placeholder="Additional Comments"
                        name="comment"  value={inputFields.comment}
                        onChange={handleChange} required/>
                  </FloatingLabel>
                  </div>
                    
                  <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                  { isEmailSend ? 
                    <Button variant="primary" disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Sending...
                      </Button>
                    : 
                    <Button variant="primary" type="submit" onClick={sendEmail}>Submit</Button>
                  }

                  </div>
                </div>
                </Form>
        </div>
      </div>
        </section>

        <ToastContainer/>
      </div>
    )
}

export default Suppliers;