import React,{ useState } from "react";
const Footer = ()=>{
    const [visible, setVisible] = useState(false) 
  
  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setVisible(true) 
    }  
    else if (scrolled <= 300){ 
      setVisible(false) 
    } 
  }; 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 


    // const redirectoWhatsapp = ()=>{
    //   window.open('https://web.whatsapp.com/send?phone=9599306055&text=','_blank')
    // }
  
  window.addEventListener('scroll', toggleVisible); 


    return (
        <React.Fragment>
            
      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" className="footer">

<div className="container">
  <div className="row gy-3">
    <div className="col-lg-4 col-md-12 footer-info">

    <a href="/" className="logo d-flex align-items-center">
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
            <img src='/assets/img/2RGBvvUU4W5Aqxk0YyDhY-transformed.png' alt="" /> 
            {/* <h1>AMRUTSYA</h1> */}
          </a>

      {/* <a href="index.html" className="logo d-flex align-items-center">
        <span>Logis</span>
      </a> */}
      {/* <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus.</p> */}
      <div className="social-links d-flex mt-4">
        {/* <a href="javascripti:void(0)" className="twitter"><i className="bi bi-twitter"></i></a> */}
        <a href="javascripti:void(0)" className="facebook"><i className="bi bi-facebook"></i></a>
        <a href="javascripti:void(0)" className="linkedin"><i className="bi bi-linkedin"></i></a>
        <a href="javascripti:void(0)" className="instagram"><i className="bi bi-youtube"></i></a>
      </div>
    </div>

    <div className="col-lg-4 col-6 footer-links">
      {/* <h4>Useful Links</h4> */}
      <ul>

      {/* {
                WEB_CONFIG.NAVIGATION.map((nav)=>(
              <li key={nav.name}>
              <NavLink  to={nav.route} 
               className={({ isActive, isPending }) =>
               isPending ? "pending" : isActive ? "active" : ""
             }>
                {nav.name}
              </NavLink>
              </li>

                ))
              } */}
        <li><a href="/">Home</a></li>
        <li><a href="#/our-team">Our Team</a></li>
        <li><a href="#/products">Products</a></li>
        <li><a href="#/suppliers">Suppliers</a></li>
        
      </ul>
    </div>

    <div className="col-lg-4 col-6 footer-links">
      {/* <h4>Our Services</h4> */}
      <ul>
        <li><a href="#/certifications">Certifications</a></li>
        <li><a href="#/contact-us">Contact Us</a></li>
        {/* <li><a href="javascripti:void(0)">Web Development</a></li>
        <li><a href="javascripti:void(0)">Product Management</a></li>
        <li><a href="javascripti:void(0)">Marketing</a></li>
        <li><a href="javascripti:void(0)">Graphic Design</a></li> */}
      {/* <a href="javascripti:void(0)" className="whatsapp"><i className="bi bi-whatsapp"></i></a> */}
      </ul>

    </div>

   
            </div>
            </div>
            <div className="container mt-4">
              <div className="copyright">
                &copy; Copyright <strong><span>Amrutsya</span></strong>. All Rights Reserved
              </div>
              {/* <div className="credits">
                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
              </div> */}
            </div>
          </footer>

          {/* <a href="javascripti:void(0)" className={"footer-whatsapp d-flex align-items-center justify-content-center active"}
          style={{display: 'inline'}} onClick={redirectoWhatsapp}><i className="bi bi-whatsapp"></i></a> */}


          <a href="javascripti:void(0)" className={"scroll-top d-flex align-items-center justify-content-center"+(visible ? ' active' : '')}
          onClick={scrollToTop}  
          style={{display: visible ? 'inline' : 'none'}}><i className="bi bi-arrow-up-short"></i></a>
        </React.Fragment>
        
    )
}

export default Footer;