import React from "react";
import CertificateHeaderImage from '../Images/sections_image/Certification.jpg';
import Certificate1 from '../Images/certificates/iec-certificate.png';
import Certificate2 from '../Images/certificates/fssai-certificate.png';
import Certificate3 from '../Images/certificates/apeda-certificate.png';

const Certifications = ()=>{


  return(
        <>
  <div className="team-component">
      {/* Full-size image covering the entire screen */}
      <img
        className="img-fluid"
        src={CertificateHeaderImage}
        alt="Team Group"
        style={{ width: '100%', height: '55vh', objectFit: 'cover' }}
      />

      {/* Team member cards */}
      <div className="container text-center">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="section-header">
                <span>Certifications</span>
                <h2>Certifications</h2>
                </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
                {/* <h3>About Us</h3> */}
                <p className="text-justify">
                We take pride in adhering to industry standards, holding certifications that underscore our commitment to excellence. 
                Explore our Certifications to discover the testament of our commitment to excellence.
                </p>
              
                
              </div>

            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
                <p>
                    The team of AMRUTSYA consists of experienced & professional set of people,
                    with credibility & trust as their ethos. The structure of the Company is very flat
                    & responsive. The key top management team consists of professionals & serial
                    entrepreneurs, with exposure to multiple businesses such as Exports & Imports,
                    Real Estate, Retail, Distribution, Logistics, Sourcing, Sustainability, Planning &
                    Conceptualisation. Each of the team members, bring in a specialty, which helps the
                    organisation to achieve its goals.
                </p>
              
                
              </div> */}
        </div>
        <section className="clients-area clients-area2 bg-white">
         <div className="container">


			<div className="affiliation-logo affiliation-logo3 wow fadeInUp" data-wow-delay="0.2s" >
        
				<span className="logo-single"><img src={Certificate1} alt="" /></span>
				<span className="logo-single"><img src={Certificate2} alt="" /></span>
			  <span className="logo-single"><img src={Certificate3} alt="" /></span>
			</div>
    </div>
		

      </section>
      </div>
    </div>
        </>
    )
}

export default Certifications;