import React from "react";

const FooterTop = ()=>{
    return(
        <React.Fragment>
            <section id="footer-top" className="footer-top-section">
            <div className="footer-top">
            {/* <div className="container"> */}
               <div className="footer-top-bg row">
                  <div className="col-lg-6 footer-box f-box1 wow fadeInUp" data-wow-delay="0.2s"><i className="bi bi-geo-alt" aria-hidden="true"></i>
                     <div className="footer-box-content">
                        <h3>Head Office</h3>
                        <p><b>Address: </b> I-91 Site- C, Surajpur Industrial Area, UPSIDC, Greater Noida, PIN 201306</p>
                     </div>
                  </div>
                  {/* <!-- Box 1 end--> */}
                  <div className="col-lg-3 footer-box f-box2 wow fadeInUp" data-wow-delay="0.4s"><i className="bi bi-telephone"></i>
                     <div className="footer-box-content">
                        <h3>Call Us</h3>
                        <p><a href="tel:919599306055">+919599306055</a></p>
						{/* <p><a href="tel:917777007249">+917777007249</a></p> */}
                     </div>
                  </div>
                  {/* <!-- Box 2 end--> */}
                  <div className="col-lg-3 footer-box f-box3 wow fadeInUp" data-wow-delay="0.6s"><i className="bi bi-envelope-open"></i>
                     <div className="footer-box-content">
                        <h3>Mail Us</h3>
                        <p><a href="mailto:info@amrutsya.com">info@amrutsya.com</a></p>
                     </div>
                  </div>
                  {/* <!-- Box 3 end--> */}
               </div>
               {/* <!-- Content row end--> */}
            {/* </div> */}
            {/* <!-- Container end--> */}
         </div>
            </section>
        </React.Fragment>

    )
}

export default FooterTop;