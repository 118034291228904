import React from "react";

const Facts = ()=>{
    return (
        <section className="fact-section">
        <div className="ts-facts-area-bg">
            <div className="container" >
               <div className="row facts-wrapper">
                  <div className="col-lg-3 col-md-6 col-sm-6 count-part wow fadeInUp">
                     <div className="ts-facts-bg"><span className="facts-icon">
                        <img src="/assets/img/facts/counter1.png"  alt=""/></span>
                        <div className="ts-facts-content">
                           <p className="facts-desc">Exporter To Over</p>
                           <h4 className="ts-facts-num"><span className="counterUp">20</span>+</h4>
                           <p className="facts-desc">Countries</p>
                        </div>
                     </div>
                     {/* <!-- Facts 1 end--> */}
                  </div>
                  {/* <!-- Col 1 end--> */}
                  <div className="col-lg-3 col-md-6 col-sm-6 count-part wow fadeInUp">
                     <div className="ts-facts-bg"><span className="facts-icon"><img src="/assets/img/facts/counter2.png" alt=""/></span>
                        <div className="ts-facts-content">
                           <p className="facts-desc">Over</p>
                           <h4 className="ts-facts-num"><span className="counterUp">1000</span></h4>
                           <p className="facts-desc">Quality Manufactures</p>
                        </div>
                     </div>
                     {/* <!-- Facts 2 end--> */}
                  </div>
                  {/* <!-- Col 2 end--> */}
                  <div className="col-lg-3 col-md-6 col-sm-6 count-part wow fadeInUp no-mar">
                     <div className="ts-facts-bg"><span className="facts-icon"><img src="/assets/img/facts/counter3.png" alt=""/></span>
                        <div className="ts-facts-content">
                           <p className="facts-desc">Over</p>
                           <h4 className="ts-facts-num"><span className="counterUp">150</span></h4>
                           <p className="facts-desc">Products</p>
                        </div>
                     </div>
                     {/* <!-- Facts 3 end--> */}
                  </div>
                  {/* <!-- Col 3 end--> */}
                  <div className="col-lg-3 col-md-6 col-sm-6 count-part wow fadeInUp  no-mar">
                     <div className="ts-facts-bg"><span className="facts-icon facts-icon2"><img src="/assets/img/facts/counter4.png" alt=""/></span>
                        <div className="ts-facts-content">
                           <p className="facts-desc">Over</p>
                           <h4 className="ts-facts-num"><span className="counterUp">100</span></h4>
                           <p className="facts-desc">Man-Years Experience</p>
                        </div>
                     </div>
                     {/* <!-- Facts 4 end--> */}
                  </div>
         
               </div>
               {/* <!-- Row end--> */}
            </div>
            {/* <!-- Container 2 end--> */}
         </div>

        </section>
    )
}

export default Facts;