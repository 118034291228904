import React, { useState } from "react";
import Slider from "../Ui/Slider";
import Facts from "./Facts";
import {useNavigate} from 'react-router-dom';
import WEB_CONFIG from "../../config";
import AboutUsImage from '../Images/HomePage-about.avif';
import Certificate1 from '../Images/certificates/iec-certificate.png';
import Certificate2 from '../Images/certificates/fssai-certificate.png';
import Certificate3 from '../Images/certificates/apeda-certificate.png';
const Home  = ()=>{

   const navigate = useNavigate();

   const [product, setProducts] = useState(WEB_CONFIG.PRODUCTS.slice(0,6));

   // const product = WEB_CONFIG.PRODUCTS.splice(0,6);

   const goToProduct=()=>{
      navigate('/products');
  }
  
  const goToCertification =()=>{
   navigate('/certifications');
  }

    return (
        <React.Fragment>
        <section id="hero" className="hero d-flex align-items-center">
            <Slider/>
        </section>
        <Facts/>


       
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container" data-aos="fade-up">

            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={AboutUsImage} className="img-fluid about-us-image" alt="" />
                  {/* <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn"></a> */}
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>About Us</h3>
                <p className="text-justify">
                Welcome to <b>AMRUTSYA</b>, your trusted partner in global trade and commerce. 
                Established with a vision to bridge boundaries and facilitate seamless international trade, 
                we take pride in being a leading merchant exporter. With an unwavering commitment to quality, 
                reliability, and customer satisfaction, we specialize in exporting a diverse range of products to destinations worldwide. 
                Our dedicated team of professionals works tirelessly to ensure that every transaction is executed with precision and adherence to the highest industry standards.
                 At AMRUTSYA, we understand the dynamic nature of global markets and leverage our expertise to provide tailored solutions that meet the unique needs of our clients. 
                 Whether you are looking for top-quality goods, efficient logistics, or unparalleled customer service, you can trust us to deliver excellence at every step. 
                 
                </p>
                <p className="text-justify">
                Join us in exploring new horizons and expanding your business reach with confidence. Welcome to a world of possibilities with <b>AMRUTSYA</b>.
                </p>
                {/* <ul>
                  <li data-aos="fade-up" data-aos-delay="100">
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5>Ullamco laboris nisi ut aliquip consequat</h5>
                      <p>Magni facilis facilis repellendus cum excepturi quaerat praesentium libre trade</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="200">
                    <i className="bi bi-fullscreen-exit"></i>
                    <div>
                      <h5>Magnam soluta odio exercitationem reprehenderi</h5>
                      <p>Quo totam dolorum at pariatur aut distinctio dolorum laudantium illo direna pasata redi</p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay="300">
                    <i className="bi bi-broadcast"></i>
                    <div>
                      <h5>Voluptatem et qui exercitationem</h5>
                      <p>Et velit et eos maiores est tempora et quos dolorem autem tempora incidunt maxime veniam</p>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>

          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <section id="service" className="services pt-0">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <span>Our Products</span>
              <h2>Our Products</h2>
            </div>
            <div className="row">


            {
                    product.map((product)=>(
                  <div className="col-lg-4 col-md-6 wow fadeInUp product-card" data-wow-delay="0.1s" key={product.productTitle}>
                     <div className="ts-service-box">
                        <div className="service-more" href="#"><div className="ts-service-image-wrapper">
                           <img className="img-fluid" src={product.imgUrl} alt="" loading="lazy"/>
                        </div>
                        <div className="ts-service-content">
                           <h3 className="service-title">{product.productTitle}</h3>
                           {/* <!-- <p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p> --> */}
                        </div></div>
                     </div>
                     {/* <!-- Service1 end--> */}
                  </div>



                     //  <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={product.productTitle}>
                     //     <Product product={product}/>
                     //  </div>
                    ))
                }






		
            </div>


            <div className="row justify-content-center mt-3">
              <div className="col-lg-8 text-center pricing-item">
                <button type="button" className="btn btn-outline-primary" style={{'fontWeight':'600'}} onClick={goToProduct}>
                    VIEW ALL PRODUCTS
                <i className="fa fa-long-arrow-right" style={{'marginLeft': '20px'}}></i>
                    </button>
              </div>
            </div>
          </div>
        </section>


        <section className="clients-area clients-area2 bg-white">
         <div className="container">
         <div className="section-header">
              <span>Affiliations</span>
              <h2>Affiliations</h2>

            </div>

			<div className="affiliation-logo affiliation-logo3 wow fadeInUp" data-wow-delay="0.2s" >
      <span className="logo-single"><img src={Certificate1} alt="" /></span>
				<span className="logo-single"><img src={Certificate2} alt="" /></span>
			  <span className="logo-single"><img src={Certificate3} alt="" /></span>

                <span className="logo-single">
                    <button type="button" className="btn btn-outline-primary" style={{'fontWeight':'600'}} onClick={goToCertification}>
                    SEE ALL<i className="fa fa-long-arrow-right" style={{'marginLeft': '20px'}}></i></button></span>
			   </div>
            </div>
		

      </section>

        </React.Fragment>
    )
}

export default Home;