import React from "react";

const Product = (props)=>{
    const {product} = props;
    return (
        <div className="ts-service-box ">
            <div className="service-more" >
                <div className="ts-service-image-wrapper">
                    <img className="img-fluid" src={product.imgUrl} alt="" loading="lazy"/>
                </div>
            <div className="ts-service-content" >
                <h3 className="service-title mt-2">{product.productTitle}
                <i className="bi bi-arrow-right m-5"></i>
                </h3>
                {/* <p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>  */}
            </div>
        </div>
     </div>
    )
}

export default Product;