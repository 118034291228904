import React from "react";
import WEB_CONFIG from "../../config";
import OurTeamHeaderImage from '../Images/OurTeamPage.avif';

const OurTeam = ()=>{


    return(
        <>
  <div className="team-component">
      {/* Full-size image covering the entire screen */}
      <img
        className="img-fluid"
        src={OurTeamHeaderImage}
        alt="Team Group"
        style={{ width: '100%', height: '55vh', objectFit: 'cover' }}
      />

      {/* Team member cards */}
      <div className="container text-center mb-4">
        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="section-header">
                <span>Our Team</span>
                <h2>Our Team</h2>
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 content">
                {/* <h3>About Us</h3> */}
                <p className="text-justify">
                  Meet the dynamic and dedicated team behind <b>AMRUTSYA</b>, the driving force that propels us to new heights in the world of international trade. 
                  Comprising seasoned professionals with diverse expertise in export management, logistics, quality control, and customer service, our team is united by a shared commitment to excellence. 
                  Each member brings a unique set of skills and experiences, contributing to the synergy that defines our success. 
                  With a passion for fostering strong client relationships and a keen understanding of global markets, our team navigates the complexities of international trade with finesse. 
                  We pride ourselves on a culture of collaboration, innovation, and continuous improvement, ensuring that we stay at the forefront of industry trends. Get to know the faces behind <b>AMRUTSYA</b>, 
                  and rest assured that your business is in capable hands with a team that is dedicated to your success.
                </p>
              
                
              </div>
        </div>
        <div className="row mt-5">
          {WEB_CONFIG.TEAM_MEMBERS.map((member) => (
            <div key={member.id} className="col-md-3">
              <div className="card mb-4 shadow rounded-4">
                <img
                  className="card-img-top rounded-circle mt-2"
                  src={member.avatar}
                  alt={`${member.name}'s Avatar`}
                  style={{ width: '140px', height: '140px', objectFit: 'cover', margin:'auto' }}
                />
                <div className="card-body">
                  <p className="card-text">{member.description}</p><hr/>
                  <h3 className="card-title">{member.name}</h3>
                  <p className="card-text">{member.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
        </>
    )
}

export default OurTeam;