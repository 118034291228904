import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import Slider1 from '../Images/carousel/Carousel-1.jpg';
import Slider2 from '../Images/carousel/Carousel-2.jpg';
import Slider3 from '../Images/carousel/Carousel-3.jpg';


const Slider = () => {

  return (
    <React.Fragment>

      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Slider1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Slider2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Slider3} alt="" />
        </SwiperSlide>
      </Swiper>
    </React.Fragment>
  )
}

export default Slider;