import React from "react";
import ProductDetailsCard from "../Ui/Product-details-card";
import {useLocation} from 'react-router-dom'
const ProductDetails= ()=>{

    const location = useLocation();
  console.log("location", location)
    return (
        <div className="product-component">
        
        <section id="product-details">
            <div className="section-header">
            <div className="breadcrumbs ">
                <nav>
                    <div className="container">
                        <ol>
                            <li><a href="/products">Our Products</a></li>
                            <li>Fruits</li>
                        </ol>
                    </div>
                </nav>
            </div>

            </div>
            <div className="container">
                <div className="row">
                <p className="fst-italic">
                    We are exporting the below fruits worldwide- 
                </p>
                </div>  
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                <ProductDetailsCard
                    imageSrc="/assets/img/storage-service.jpg"
                    heading="Card Heading"
                    description="This is a sample card description. Customize it as needed."
                />

                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default ProductDetails;