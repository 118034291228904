import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import WEB_CONFIG from "../../config";
import Logo from '../Images/2RGBvvUU4W5Aqxk0YyDhY-transformed.png';

const Header = ()=>{


  const [isToggled, setToggle] = useState(true);
  const toggleMobileNav = ()=>{
      document.body.classList.add('mobile-nav-active');
      setToggle(false);
  }
  const hideMobileNav =()=>{
    document.body.classList.remove('mobile-nav-active');
    setToggle(true);
  }
    return (
        <React.Fragment>
            <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

          <a href="/" className="logo d-flex align-items-center">
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
            <img src={Logo} alt="" /> 
            <h1>AMRUTSYA</h1>
          </a>
          { isToggled ?
            <i className="mobile-nav-toggle mobile-nav-show bi bi-list" onClick={toggleMobileNav}></i>
            : 
            <i className="mobile-nav-toggle mobile-nav-hide bi bi-x" onClick={hideMobileNav}></i>

          }
          <nav id="navbar" className="navbar">
            <ul>

              {
                WEB_CONFIG.NAVIGATION.map((nav)=>(
              <li key={nav.name}>
              <NavLink  to={nav.route} 
               className={({ isActive, isPending }) =>
               isPending ? "pending" : isActive ? "active" : ""
             }>
                {nav.name}
              </NavLink>
              </li>

                ))
              }
           
            </ul>
          </nav>
          {/* <!-- .navbar --> */}

        </div>
      </header>
        </React.Fragment>
    )
}

export default Header;